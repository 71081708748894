import Accordion from '@mui/material/Accordion'
import { AccordionProps } from '@mui/material/Accordion'
import { calcElevationString } from '@/style/palette'
import { useIsMdUp } from '@/core/hooks/useMediaQueries'

export interface SidebarAccordionProps extends AccordionProps {
  elevationContent?: number
  elevationBackground?: number
  iconOnly?: boolean
  tier1?: boolean
  complex?: boolean
}

export const SidebarAccordion = ({
  children,
  elevationContent = 0,
  elevationBackground = 3,
  iconOnly,
  tier1,
  complex,
  ...rest
}: SidebarAccordionProps) => {
  const isMdUp = useIsMdUp()
  const clipPath = iconOnly
    ? 'path("M0 36C0 23.1034 10.4548 12.6486 23.3514 12.6486H63.2111C70.2197 12.6486 75.9227 7.0081 76 0V0V72V72C75.9227 64.9919 70.2197 59.3514 63.2111 59.3514H23.3514C10.4548 59.3514 0 48.8966 0 36V36Z")'
    : 'path("M0 36C0 23.1034 10.4548 12.6486 23.3514 12.6486H254.85C261.919 12.6486 267.725 7.06376 268 0V0V72V72C267.725 64.9362 261.919 59.3514 254.85 59.3514H23.3514C10.4548 59.3514 0 48.8966 0 36V36Z")'
  return (
    <Accordion
      {...rest}
      sx={theme => ({
        background: 'none',
        '&:before': {
          display: 'none'
        },
        '& .MuiAccordionSummary-root': {
          padding: 0,
          position: 'relative',
          height: 48,
          marginBottom: theme.spacing(1),
          paddingX: tier1 ? 0 : 1,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        '& .MuiAccordionSummary-content': {
          borderRadius: complex ? theme.spacing(2) : isMdUp && tier1 ? 0 : 6,
          clipPath: isMdUp && tier1 ? clipPath : 'none',
          height: isMdUp && tier1 ? 72 : iconOnly ? 48 : 'auto',
          margin: 0,
          marginBottom: isMdUp ? -12 : 0,
          marginTop: isMdUp ? -12 : 0,
          overflow: 'hidden',
          paddingLeft: complex ? 1.25 : 5,
          paddingRight: complex ? 1.25 : isMdUp ? 4 : 0,
          position: 'relative',
          color: theme.palette.text.primary,
          /* Background Gradient */
          '&:before': {
            background: theme.palette.primary.main,
            backgroundImage: tier1
              ? calcElevationString(theme.palette.mode, [
                  elevationContent,
                  8,
                  elevationBackground,
                  elevationBackground
                ])
              : calcElevationString(theme.palette.mode, [6]),
            content: '""',
            height: '100%',
            position: 'absolute',
            right: -2,
            top: 0,
            transform: 'scaleX(0)',
            transition: 'transform .3s ease-in',
            transformOrigin: 'right',
            width: '100%'
          }
        },
        /* Accordion Hover */
        '&:hover .MuiAccordionSummary-content': {
          '&:after': {
            background: theme.palette.primary.main,
            backgroundImage: calcElevationString(theme.palette.mode, [1]),
            content: '""',
            position: 'absolute',
            right: tier1 ? 12 : 0,
            left: 0,
            top: tier1 ? 12 : 0,
            bottom: tier1 ? 12 : 0,
            borderRadius: theme.spacing(6),
            zIndex: -1
          }
        },
        /* Accordion Active Hover */
        '&:has(.active):hover .MuiAccordionSummary-content': {
          '&:after': {
            background: 'none',
            backgroundImage: 'none'
          }
        },
        /* Active Accordion */
        '&:has(.active) .MuiAccordionSummary-content': {
          color: tier1 ? 'inherit' : theme.palette.text.primary,
          /* Animate Background Gradient */
          '&:before': {
            transform: 'scaleX(1.5)',
            transition: 'transform .5s ease-out',
            zIndex: -1
          }
        },
        /* Accordion Child */
        '& .MuiLink-root .MuiButtonBase-root': {
          paddingLeft: iconOnly ? 3 : 11,
          paddingRight: 2,
          '& .MuiTypography-root': {
            color: theme.palette.text.secondary,
            transition: 'color 0.3s ease',
            display: iconOnly ? 'none' : 'block'
          },
          '&:hover': {
            backgroundColor: 'transparent'
          },
          '&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: theme.spacing(5),
            borderLeft: '1px solid',
            borderColor: theme.palette.text.secondary,
            height: 'calc(100% + 4px)',
            display: iconOnly ? 'none' : 'block'
          },
          '&:after': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: theme.spacing(5),
            borderLeft: '1px solid',
            borderBottom: '1px solid',
            borderBottomLeftRadius: '4px',
            borderColor: theme.palette.text.secondary,
            height: 'calc(50% + 2px)',
            width: 12,
            display: iconOnly ? 'none' : 'block'
          }
        },
        /* Last Accordion Child */
        '& .MuiLink-root:last-child .MuiButtonBase-root': {
          '&:before': {
            height: '30%'
          }
        },
        /* Active Accordion Child */
        '& .MuiLink-root.active, .MuiLink-root:hover': {
          backgroundColor: 'transparent',
          '& .MuiButtonBase-root .MuiTypography-root': {
            color: theme.palette.text.primary
          }
        }
      })}
    >
      {children}
    </Accordion>
  )
}
